<template>
  <el-submenu :index="menuObj.code">
    <div slot="title" :style="`padding-left:${layer}px;`">
      <i :class="`iconfont ${menuObj.icon}`"></i>
      <span
        >{{ menuObj.menuName }}<span class="red_spot" v-if="redSpot(menuObj.children)"></span>
      </span>
    </div>
    <template v-for="(item, index) in menuObj.children">
      <template v-if="item.children && item.children.length > 0">
        <VerMenuEleItem :menuObj="item" :layer="layer + 25"></VerMenuEleItem>
      </template>
      <template v-else>
        <el-menu-item
          v-if="item.linkTo || item.href"
          :index="item.code"
          :key="item.code"
          @click.native="jump(item, index)"
          :class="index == activeIndex ? 'color_f' : 'color_d'"
          :style="`padding-left:${layer + 25}px; `"
        >
          <i :class="`iconfont ${item.icon}`"></i>
          <span slot="title">
            <a target="_blank" @click="leftBlockClick" :href="baseUrl + item.href">{{
              item.menuName
            }}</a>
            <!-- {{ item.menuName }} -->
            <span v-if="item.backlog" class="red_spot"></span>
          </span>
        </el-menu-item>
      </template>
    </template>
  </el-submenu>
</template>
<script>
export default {
  name: 'VerMenuEleItem',
  components: {
    VerMenuEleItem: () => import('./VerMenuEleItem.vue'),
  },
  props: {
    layer: {
      type: Number,
      default: 20,
    },
    menuObj: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  inject: ['reload'],
  data() {
    return {
      activeIndex: 0,
      // paddingLeft:`padding-left:${this.layer*10}px;`,
      baseUrl: window.location.origin + window.location.pathname + '#',
    }
  },
  watch: {
    layer(a, b) {
      // this.paddingLeft = `padding-left:${a*10}px;`
    },
  },
  created() {},
  methods: {
    leftBlockClick(e) {
      e.preventDefault()
    },
    jump(item, index) {
      this.activeIndex = index
      if (item.backlog) {
        this.$api.menu.read(item.code).then(res => {})
      }

      if (item.code == this.$route.name) {
        this.reload()
      } else {
        var path = item.linkTo ? item.linkTo : item.href
        this.$router.push(path)
      }
      item.backlog = false
    },
    redSpot(row) {
      let arr = 0
      row.some(function (v) {
        if (v.backlog == true) {
          arr = 1
        }
      })
      return arr
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/config.scss';

* {
  &:hover {
    background: none !important;
  }
}
.color_d {
  color: #fff !important;
}
.color_f {
  color: #fff !important;
}
.red_spot {
  width: 8px;
  height: 8px;
  margin-left: 10px;
  border-radius: 50%;
  display: inline-block;
  background: #f00;
  vertical-align: middle;
}

/deep/ .el-menu--inline {
  background: #0a5d79 !important;
  //   background: linear-gradient(#176580, #135166);
}
</style>
